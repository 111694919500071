<template>
  <div>
    <v-carousel hide-delimiters class="owl-carousel owl-theme home_slider" show-arrows="hover" v-if="slider.length > 0">
      <v-carousel-item class="slider-item" v-for="slide in slider" :key="slide.itiId">
        <v-sheet 
        :style="{backgroundImage: `url('${$imgURL + slide.insInstitucionalImagens[0].itgUrlImagem}')`,backgroundSize:'100%', backgroundRepeat:'no-repeat', height:'500px'}">
        <div class="home_container">
			 			<div class="container">
			 				<div class="row">
			 					<div class="col">
			 						<div class="home_content">
			 							<div class="home_title"><h1>{{slide.itiNome}}</h1></div>
										
			 						</div>
			 					</div>
			 				</div>
			 			</div>
			 		</div>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

    <!-- Search -->

	<div class="search">
		<div class="container">
			<div class="row">
				<div class="col">
					<div class="search_container">
						<div class="search_title">Entre em Contato</div>
						<div class="search_form_container">
							<form action="#" class="search_form" id="search_form">
								<div class="d-flex flex-lg-row flex-column align-items-start justify-content-lg-between justify-content-start">
									<div class="search_inputs d-flex flex-lg-row flex-column align-items-start justify-content-lg-between justify-content-start">
										<input type="text" v-model="Nome" class="search_input" placeholder="Nome" required="required">
										<input type="text" v-model="Email" class="search_input" placeholder="E-mail" required="required">
										<input type="text" v-model="Telefone" class="search_input" placeholder="Telefone" required="required">
									</div>
									<button class="search_button" @click.prevent="EnviaEmail()">Enviar</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
 
	<!-- Featured -->

	<div class="featured" v-if="condominios!=''">
		<div class="container">
			<div class="row">
				<div class="col">
					<div class="section_title_container text-center">
						<div class="section_subtitle"></div>
						<div class="section_title"><h1>Nossos Condomínios</h1></div>
					</div>
				</div>
			</div>
			<div class="row featured_row">
				
				<!-- Featured Item -->
				<div class="col-lg-4"  v-for="condominio in condominios" :key="condominio.itiId">
					<div class="listing">
						<div class="listing_image">
							<div class="listing_image_container">
								<img :src="$imgURL + condominio.insInstitucionalImagens[0].itgUrlImagem" alt="" style="height: 200px; width: 350px;">
							</div>
							<div class="tags d-flex flex-row align-items-start justify-content-start flex-wrap">
								<div class="tag tag_house"></div>
								<div class="tag tag_sale" v-if="contato!=''"><a :href="contato[0].itiCampoExtra5" target="_blank">Reserve</a></div>
							</div>
							<div class="tag_price listing_price">{{condominio.itiNome}}</div>
						</div>
						<div class="listing_content">
							<div class="prop_location listing_location d-flex flex-row align-items-start justify-content-start">
								<img src="/images/icon_1.png" alt="">
								<router-link :to="'/condominiosDetalhe/' + condominio.itiId + '/' + formataLink(condominios[0].itiNome)">Veja a localização e mais informações sobre o Condomínio. </router-link>
							</div>
						</div>
					</div>
				</div>


			</div>
		</div>
	</div>

  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'HomeView',
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
  
  slider:[],
  formhome:[],
  condominios:[],
  contato:[],
  Nome:'',
  Email:'',
  Telefone:'',
  
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Home - Viannas Participações',
        meta: [
          { name: "description", content: "" },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
    alterarItemAtivo(index) {
      this.itemAtivo = index; 
    },
    formattedDate(value) {
      return this.$moment(value).format('DD/MM/YYYY')
    },
    formataLink(value){
      return value.replaceAll('/','').replaceAll(' ','-').replaceAll('.','');
    },
    EnviaEmail(){
      if (this.Nome == '') {
        this.$mensagemAviso("Nome Obrigatório.")
      }
      else if (this.Email == '') {
        this.$mensagemAviso("E-mail Obrigatório.")
      }
      else {
        let _dadosEmail = {
          nomeDe: this.Nome,
          nomePara: "Viannas Locações",
          assunto: 'Contato do Site Home - ' + this.Nome,
          destinatario: this.contato[0].itiCampoExtra1,
          emailResposta: this.Email,
          textoEmail: "Nome: " + this.Nome + "<br>" + "E-mail: " + this.Email + "<br>Telefone: " + this.Telefone,
          nomeAnexo: '',
          anexo: '',
          tipoAnexo: ''
        }
        axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
          .then(response => {
            if (response.data >= 1) {
              this.$mensagemSucesso("E-mail enviado com sucesso.")
              this.Nome = "";
              this.Email = "";
              this.Telefone = "";
            }
          });
      }
    },
  },
  async created() {
    var _idioma = await this.ValidaIdioma();

    this.carregaMetas();
    axios.get('/Process/InsInstitucional/institucional/28/slider')
      .then(response => {
          this.slider = response.data.sort((a, b) => {
                    const valorA = parseInt(a.itiOrdem) || 0;
                    const valorB = parseInt(b.itiOrdem) || 0;
                    return valorA - valorB;
                });
                console.log('slider')
                console.log(this.slider)
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/30/condominios')
      .then(response => {
          this.condominios = response.data.filter(x=> x.itiVisivel && x.itiDestaque ).sort((a, b) => {
                    const valorA = parseInt(a.itiOrdem) || 0;
                    const valorB = parseInt(b.itiOrdem) || 0;
                    return valorA - valorB;
                });
                console.log('condominios')
                console.log(this.condominios)
                this.condominios.forEach(condominio =>{
                  condominio.insInstitucionalImagens.sort((a, b) => {
                    const valorA = parseInt(a.itgOrdem) || 0;
                    const valorB = parseInt(b.itgOrdem) || 0;
                    return valorA - valorB;
                });
                })
               
      })
      .catch(error => {
          console.error(error);
      });
      axios.get('/Process/InsInstitucional/institucional/16/contato')
      .then(response => {
          this.contato = response.data
          console.log('contato')
          console.log(this.contato)
      })
      .catch(error => {
          console.error(error);
      });

      
  },
}
</script>

<style>

.alttp {
  padding-top: 100px;
}

@media (max-width:767px) {
.owl-carousel.home-slider {
    position: relative;
    height: 130px !important; 
    z-index: 0;
}
.alttp {
  padding-top: 30px;
}
}


</style>
