<template>
  <div>
    <!-- Home -->

    <div class="home alturatopocel" v-if="contatotopo!=''">
      <div class="parallax_background parallax-window" data-parallax="scroll"  data-speed="0.8" 
      :style="{backgroundImage: `url('${$imgURL + contatotopo[0].insInstitucionalImagens[0].itgUrlImagem}')`, backgroundRepeat:'no-repeat'}"></div>
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="text-center">
                <div class="home_title" style="color: #fff; font-size: 30px; font-weight: 500;">{{contatotopo[0].itiNome}}</div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>

  <!-- Contact -->

	<div class="contact" v-if="contato!=''">
		<div class="container">
			<div class="row">
				<div class="col">
					<div class="section_title_container text-center">
						<div class="section_title"><h1>{{contato[0].itiNome}}</h1></div>
					</div>
				</div>
			</div>
			<div class="row contact_row">

				<!-- Contact - About -->
				<div class="col-lg-4 contact_col">
					<div class="footer_logo" ><router-link to="/"><img src="/images/logo1.jpg" style="height: 60px; width: auto;" alt=""></router-link></div>
					<div class="contact_text">
						<p style="text-align: justify;">{{contato[0].itiReferencia}}
						</p>
					</div>
				</div>

				<!-- Contact - Info -->
				<div class="col-lg-4 contact_col">
					<div class="contact_info">
						<ul>
							<li class="d-flex flex-row align-items-center justify-content-start">
								<div class="d-flex flex-column align-items-center justify-content-center">
									<div><img src="/images/phone-call-2.svg" alt=""></div>
								</div>
								<span><a :href="contato[0].itiCampoExtra6" target="_blank" style="color: #000;">{{contato[0].itiCampoExtra3}}</a></span>
							</li>
							<li class="d-flex flex-row align-items-center justify-content-start">
								<div class="d-flex flex-column align-items-center justify-content-center">
									<div><img src="/images/phone-call-2.svg" alt=""></div>
								</div>
								<span><a :href="contato[0].itiCampoExtra5" target="_blank"  style="color: #000;">{{contato[0].itiCampoExtra4}}</a></span>
							</li>
							<li class="d-flex flex-row align-items-center justify-content-start">
								<div class="d-flex flex-column align-items-center justify-content-center">
									<div><img src="/images/envelope-2.svg" alt=""></div>
								</div>
								<span>{{contato[0].itiCampoExtra2}}</span>
							</li>
						</ul>
					</div>
				</div>

				<!-- Contact - Image -->
				<div class="col-lg-4 contact_col">
					<div class="contact_image d-flex flex-column align-items-center justify-content-center">
						<img :src="$imgURL + contato[0].insInstitucionalImagens[0].itgUrlImagem" alt="">
					</div>
				</div>

			</div>
			<div class="row contact_form_row">
				<div class="col">
					<div class="contact_form_container">
						<form action="#" class="contact_form text-center" id="contact_form">
							<div class="row">
								<div class="col-lg-4">
									<input type="text" v-model="Nome" class="contact_input" placeholder="Nome" required="required">
								</div>
								<div class="col-lg-4">
									<input type="email" v-model="Email" class="contact_input" placeholder="E-mail" required="required">
								</div>
								<div class="col-lg-4">
									<input type="text" v-model="Telefone" class="contact_input" placeholder="Telefone" required="required">
								</div>
							</div>
							<textarea class="contact_textarea contact_input" v-model="Mensagem" placeholder="Mensagem" required="required"></textarea>
							<button class="contact_button"  @click.prevent="EnviaEmail()">Enviar mensagem</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>


</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import axios from 'axios';
import { useHead } from '@vueuse/head';

export default {
  name: 'ContatoView',
  components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
    contatotopo:[],
    Dados:[],
    contato:[],
    Nome:'',
    Email:'',
    Telefone:'',
    Mensagem:'',
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Contato - Viannas Participações',
        meta: [
          { name: "description", content: "" },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
    EnviaEmail(){
      if (this.Nome == '') {
        this.$mensagemAviso("Nome Obrigatório.")
      }
      else if (this.Email == '') {
        this.$mensagemAviso("E-mail Obrigatório.")
      }
      else {
        let _dadosEmail = {
          nomeDe: this.Nome,
          nomePara: "Viannas Locações",
          assunto: 'Contato do Site - ' + this.Nome,
          destinatario: this.contato[0].itiCampoExtra1,
          emailResposta: this.Email,
          textoEmail: "Nome: " + this.Nome + "<br>" + "E-mail: " + this.Email + "<br>Telefone: " + this.Telefone + "<br>Mensagem: " + this.Mensagem,
          nomeAnexo: '',
          anexo: '',
          tipoAnexo: ''
        }
        axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
          .then(response => {
            if (response.data >= 1) {
              this.$mensagemSucesso("E-mail enviado com sucesso.")
              this.Nome = "";
              this.Email = "";
              this.Telefone = "";
              this.Mensagem = "";
            }
          });
      }
    },
  },
  async created() {
    var _idioma = await this.ValidaIdioma();
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/15/contatotopo')
      .then(response => {
          this.contatotopo = response.data
      })
      .catch(error => {
          console.error(error);
      });


      axios.get('/Process/InsInstitucional/institucional/16/contato')
      .then(response => {
          this.contato = response.data
      })
      .catch(error => {
          console.error(error);
      });
      
  },
}
</script>

<style></style>
