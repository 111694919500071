<template>
  <div>
	<div class="home alturatopocel" v-if="condominiotopo!=''">
      <div class="parallax_background parallax-window" data-parallax="scroll"  data-speed="0.8" 
      :style="{backgroundImage: `url('${$imgURL + condominiotopo[0].insInstitucionalImagens[0].itgUrlImagem}')`,backgroundRepeat:'no-repeat'}"></div>
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="text-center">
                <div class="home_title" style="color: #fff; font-size: 30px; font-weight: 500;">{{condominiotopo[0].itiNome}}</div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>


  <div class="container"  v-if="detalhe!=''" >
		<div class="row">
			<div class="col-lg-12" style="padding-top: 50px; padding-bottom: 30px; position: relative; min-height: 600px;">
				<div class="row">
					<div class="col-lg-8" >
					  <div>
						<div>
						  <div v-if="detalhe[0].insInstitucionalImagens !=''"><img :src="$imgURL + detalhe[0].insInstitucionalImagens[0].itgUrlImagem" alt="Image" class="img-fluid"></div>
						</div>
					  </div>
					  <div class="bg-white property-body border-bottom border-left border-right" style="padding: 30px;">
						<div class="row mb-5">
						  <div class="col-md-12" style="padding-top: 30px;">
							<strong class="text-success h1 mb-3" style="color: #0098ff !important;">{{detalhe[0].itiNome}}</strong>
						  </div>
						</div>
						
						<h2 class="h4 text-black">Mais Informações</h2>
					<p style="text-align: justify;" v-html="detalhe[0].itiResumo1">
					</p>
					<h2 class="h4 text-black mb-3" style="padding-top: 40px;">Galeria</h2>
					
						<div class="container">
						<div class="row align-items-center d-flex">
							<div class="row gallery-item">
							<div class="images" v-viewer v-if="ImagensDado.length > 1">
								<img v-for="img in ImagensDado" :key="img.itgId" :src="$imgURL + img.itgUrlImagem" style="height: 150px;padding: 10px;cursor: pointer;">
							</div>
							<div v-else>
								<img v-for="img in ImagensDado" :key="img.itgId" :src="$imgURL + img.itgUrlImagem" style="padding: 10px;cursor: pointer;width: 100%;">
							</div>
							</div>
						</div>
						</div>
					</div>
					</div>
					<div class="col-lg-4">
					  <div class="bg-white widget border rounded" style="padding: 10px; padding-bottom: 20px;">
						<h3 class="h4 text-black widget-title mb-3" style="margin-left: 30px; padding-top: 30px;">Tenho Interesse</h3>
						<form action="" class="form-contact-agent">
						  <div class="form-group">
							<label for="name" style="margin-left: 30px;">Nome</label>
							<input type="text" v-model="Nome" id="name" class="form-control">
						  </div>
						  <div class="form-group">
							<label for="email" style="margin-left: 30px;">E-mail</label>
							<input type="email" v-model="Email" id="email" class="form-control">
						  </div>
						  <div class="form-group">
							<label for="phone" style="margin-left: 30px;">Telefone</label>
							<input type="text" v-model="Telefone" id="telefone" class="form-control">
						  </div>
						  <div class="form-group" style="margin-left: 30px;">
							<input type="submit" id="phone" class="btn btn-primary" @click.prevent="EnviaEmail()" value="Enviar mensagem">
						  </div>
						</form>
					  </div>
					</div>
				  </div>
			</div>
		</div>
	</div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useHead } from '@vueuse/head';
import { useRoute } from 'vue-router';
import CallAction from '@/components/PageCallAction.vue'
import axios from 'axios';

export default {
  name: 'CondominiosDetalheView',
  components: {
    Swiper,
    SwiperSlide,
    CallAction
  },
  setup() {
        const route = useRoute();
        const id = route.params.id;
        const nomeProduto = route.params.nome;
        return {
            id
        };
    },
  data: () => ({
    condominiotopo:[],
    detalhe:[],
	contato:[],
	ImagensDado:[],
	Nome:'',
  	Email:'',
  	Telefone:'',
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Condomínios Detalhe - Viannas Participações',
        meta: [
          { name: "description", content: "" },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
	EnviaEmail(){
      if (this.Nome == '') {
        this.$mensagemAviso("Nome Obrigatório.")
      }
      else if (this.Email == '') {
        this.$mensagemAviso("E-mail Obrigatório.")
      }
      else {
        let _dadosEmail = {
          nomeDe: this.Nome,
          nomePara: "Viannas Locações",
          assunto: 'Tenho Interesse - ' + this.Nome,
          destinatario: this.contato[0].itiCampoExtra1,
          emailResposta: this.Email,
          textoEmail: "Nome: " + this.Nome + "<br>" + "E-mail: " + this.Email + "<br>Telefone: " + this.Telefone,
          nomeAnexo: '',
          anexo: '',
          tipoAnexo: ''
        }
        axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
          .then(response => {
            if (response.data >= 1) {
              this.$mensagemSucesso("E-mail enviado com sucesso.")
              this.Nome = "";
              this.Email = "";
              this.Telefone = "";
            }
          });
      }
    },
  },
  async created() {
    var _idioma = await this.ValidaIdioma();
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/29/condominiotopo')
      .then(response => {
          this.condominiotopo = response.data
      })
      .catch(error => {
          console.error(error);
      });
	  axios.get('/Process/InsInstitucional/institucional/30/condominios')
      .then(response => {
          this.detalhe = response.data.filter(x=> x.itiId==this.id)
                console.log('detalhe')
                console.log(this.detalhe)
                this.detalhe.forEach(condominio =>{
                  condominio.insInstitucionalImagens.sort((a, b) => {
                    const valorA = parseInt(a.itgOrdem) || 0;
                    const valorB = parseInt(b.itgOrdem) || 0;
                    return valorA - valorB;
                });
                });
				this.ImagensDado=this.detalhe[0].insInstitucionalImagens;
      })
      .catch(error => {
          console.error(error);
      });
	  axios.get('/Process/InsInstitucional/institucional/16/contato')
      .then(response => {
          this.contato = response.data
          console.log('contato')
          console.log(this.contato)
      })
      .catch(error => {
          console.error(error);
      });
  },
}
</script>

<style></style>
