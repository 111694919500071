<template>
  <div>
    <!-- Home -->

    <div class="home alturatopocel" v-if="blogtopo!=''">
      <div class="parallax_background parallax-window" data-parallax="scroll"  data-speed="0.8" 
      :style="{backgroundImage: `url('${$imgURL + blogtopo[0].insInstitucionalImagens[0].itgUrlImagem}')`,backgroundRepeat:'no-repeat'}"></div>
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="text-center">
                <div class="home_title" style="color: #fff; font-size: 30px; font-weight: 500;">{{blogtopo[0].itiNome}}</div>
              </div>
            </div>
          </div>
        </div>
    </div>

    <!-- Blog -->

	<div class="blog">
		<div class="container">
			<div class="row">
				
				<!-- Blog Posts -->
				<div class="col-lg-9">
					<div class="blog_posts">

						<!-- Blog Post -->
						<div class="blog_post">
							<div class="blog_post_image">
								<img :src="$imgURL + Imagem" alt="">
								<div class="blog_post_date d-flex flex-column align-items-center justify-content-center">
									<div>{{Dia}}</div>
                  <div>{{Mes}}</div>
								</div>
							</div>
							<div class="blog_post_content">
								<div class="blog_post_title" style="font-size: 30px; font-weight: 500; color: #000; ">{{Titulo}}</div>
							
								<div class="blog_post_text">
									<p style="text-align: justify;" v-html="Texto"></p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Sidebar -->
				<div class="col-lg-3" v-if="blog.length > 0">
					<div class="sidebar" >
						<!-- Categories -->
						<div class="categories">
							<div class="sidebar_title" style="padding-bottom: 30px;"><h3>Últimas Notícias</h3></div>
							<div class="sidebar_list">
								<ul>
									<li v-for="itemx in blog" :key="itemx.itiId"><a href="#" @click.prevent="CarregaNoticia(itemx)" style="padding-top: 30px;">{{itemx.itiNome}}</a></li>
								
								</ul>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>

  </div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useHead } from '@vueuse/head';
import CallAction from '@/components/PageCallAction.vue'
import axios from 'axios';

export default {
  name: 'BlogView',
  components: {
    Swiper,
    SwiperSlide,
    CallAction
  },
  data: () => ({
    blogtopo:[],
    blog:[],
    Imagem:'',
    Titulo:'',
    Dia:'',
    Mes:'',
    Texto:'',
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Blog - Viannas Participações',
        meta: [
          { name: "description", content: "" },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    formattedDate(value) {
      return this.$moment(value).format('DD/MM/YYYY')
    },
    formataLink(value){
      return value.replaceAll('/','').replaceAll(' ','-').replaceAll('.','');
    },
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
    CarregaNoticia(itemx){
      this.Titulo=itemx.itiNome;
      this.Dia=this.$moment(itemx.itiData1).format('DD');
      this.Mes=this.$moment(itemx.itiData1).format('MM/YYYY');
      this.Texto=itemx.itiResumo1;
      this.Imagem=itemx.insInstitucionalImagens[0].itgUrlImagem;
    }
  },
  async created() {
    var _idioma = await this.ValidaIdioma();
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/31/blogtopo')
      .then(response => {
          this.blogtopo = response.data
          console.log('blogtopo')
          console.log(this.blogtopo)
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/32/blog')
      .then(response => {
          this.blog = response.data.filter(x=> x.itiVisivel).sort((a, b) => {
            const dataA = new Date(a.itiData1);
            const dataB = new Date(b.itiData1);

            return dataA - dataB;
          }).reverse();
          this.CarregaNoticia(this.blog[0]);
      })
      .catch(error => {
          console.error(error);
      });

  },
}
</script>

<style></style>
