
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import Sobre from '../views/SobreView.vue'
import Condominios from '../views/CondominiosView.vue'
import CondominiosDetalhe from '../views/CondominiosDetalheView.vue'
import Blog from '../views/BlogView.vue'
import Contato from '../views/ContatoView.vue'


const routes = [
 { path: '/', name: 'Home', component: Home },
 { path: '/Sobre', name: 'Sobre', component: Sobre },
 { path: '/Condominios', name: 'Condominios', component: Condominios },
 { path: '/CondominiosDetalhe/:id:/:nome', name: 'CondominiosDetalhe', component: CondominiosDetalhe },
 { path: '/Blog', name: 'Blog', component: Blog },
 { path: '/Contato', name: 'Contato', component: Contato },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  next()
})

export default router
