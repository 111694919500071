<template>
  <div>
    <!-- Aqui vai menu computador-->
    <div class="hidden-sm hidden-xs">
    <header class="header">
		
		<!-- Header Bar -->
		<div class="header_bar d-flex flex-row align-items-center justify-content-start" v-if="cabecalho!= ''">
			<div class="header_list">
				<ul class="d-flex flex-row align-items-center justify-content-start">
					<!-- Phone -->
					<li class="d-flex flex-row align-items-center justify-content-start">
						<div><img src="/images/phone-call.svg" alt=""></div>
						
						<span><a :href="cabecalho[0].itiLinkBtn1" target="_blank" style="color: #fff;">{{cabecalho[0].itiNome}}</a></span> <span><a :href="cabecalho[0].itiLinkBtn1" target="_blank" style="color: #fff;">{{cabecalho[0].itiReferencia}}</a>
            </span>
					</li>
					<!-- Email -->
					<li class="d-flex flex-row align-items-center justify-content-start">
						<div><img src="/images/envelope.svg" alt=""></div>
						<span>{{cabecalho[0].itiCampoExtra1}}</span>
					</li>
				</ul>
			</div>
			<div class="ml-auto d-flex flex-row align-items-center justify-content-start">
				<div class="social">
					<ul class="d-flex flex-row align-items-center justify-content-start" v-if="redes!=''">
						<li v-if="redes[0].itiReferencia!=''"><a :href="redes[0].itiReferencia"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
            <li v-if="redes[0].itiNome!=' '"><a :href="redes[0].itiNome"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
            <li v-if="redes[0].itiCampoExtra1!=''"><a :href="redes[0].itiCampoExtra1"><i class="fa fa-youtube" aria-hidden="true"></i></a></li>
            <li v-if="redes[0].itiCampoExtra2!=''"><a :href="redes[0].itiCampoExtra2"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
          </ul>
				</div>
			</div>
		</div>

		<!-- Header Content -->
		<div class="header_content d-flex flex-row align-items-center justify-content-start" v-if="cabecalho!= ''">
			<div class="logo"><router-link to="/"><img src="/images/logo1.jpg" style="height: 40px; width: auto;" alt=""></router-link></div>
			<nav class="main_nav">
				<ul class="d-flex flex-row align-items-start justify-content-start">
					<li><router-link to="/">Home</router-link></li>
					<li><router-link to="/sobre">Sobre</router-link></li>
					<li><router-link to="/condominios">Nossos Condomínios</router-link></li>
					<li style="display: none;"><router-link to="/blog">Blog</router-link></li>
					<li><router-link to="/contato">Contato</router-link></li>
				</ul>
			</nav>
			<div class="submit ml-auto"><a :href="cabecalho[0].itiCampoExtra3" target="_blank" style="color: #fff;">{{cabecalho[0].itiCampoExtra2}}</a></div>
			<div class="hamburger ml-auto"><i class="fa fa-bars" aria-hidden="true"></i></div>
		</div>

	</header>
  </div>


    <!-- Aqui vai menu para celular-->
   	 <div class="hidden-md hidden-lg" v-if="cabecalho!= ''">
      <div class="container" style="padding-bottom: 15px;">
        <div class="row">
          <div class="col-4" style="padding-top: 15px;">
            <v-menu width="300" style="padding-left: 20px;">
              <template v-slot:activator="{ props }">
                <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <router-link to="/">
                    <v-list-item-title class="menuTamanho">Home</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link to="/sobre">
                    <v-list-item-title class="menuTamanho">Sobre</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link to="/condominios">
                    <v-list-item-title class="menuTamanho">Nossos Condomínios</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item style="display: none;">
                  <router-link to="/blog">
                    <v-list-item-title class="menuTamanho">Blog</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link to="/contato">
                    <v-list-item-title class="menuTamanho">Contato</v-list-item-title>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="col-8" style="display:flex; align-items: end; justify-content: end;">
            <router-link to="/" class="navbar-brand">
              <img src="/images/logo1.jpg" style="width: 250px;padding-top: 15px;" /></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'PageHeader',
  components: {

  },
  data: () => ({
    Idioma: 0,
    topo: [],
    showModernizacaoMenu: false,
    cabecalho:[],
    redes:[]
  }),
  methods: {
    setaIdioma(value){
      if (localStorage.getItem('idiomaGlobus')) {
         localStorage.removeItem('idiomaGlobus');
         localStorage.setItem('idiomaGlobus', value);
      } else {
         localStorage.setItem('idiomaGlobus', value);
      }
    },
    ValidaIdioma(){
      if (localStorage.getItem('idiomaGlobus')) {
        return localStorage.getItem('idiomaGlobus');
      }
      else{
        return 1;
      }
    },
    toggleModernizacaoMenu() {
      this.showModernizacaoMenu = !this.showModernizacaoMenu;
    },
    formataLink(value){
      return value.replaceAll('/','').replaceAll(' ','-').replaceAll('.','');
    }
  },
 created() {    

  axios.get('/Process/InsInstitucional/institucional/9/cabecalho')
            .then(response => {
                this.cabecalho = response.data;
            })
            .catch(error => {
                console.error(error);
            });

  axios.get('/Process/InsInstitucional/institucional/20/redessociais')
      .then(response => {
          this.redes = response.data
          console.log('this.redes')
          console.log(this.redes)
      })
      .catch(error => {
          console.error(error);
      });
  }
}
</script>

<style scoped>
.menuTamanho {
  font-size: 20px;
  text-align: center;
}

#modernizacaoDropdown {
  position: absolute;
  top: 100%; /* Posiciona abaixo do item "Modernização" */
  left: 0;
}

/* Estilos para o submenu */
.displaymenu {
  display: block;
}



</style>
