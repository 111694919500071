<template>
 <v-app>
    <v-main>
      <Loader v-if="$loading.value"></Loader>
          <Header />
            <router-view :key="$route.fullPath" />
            <FooterPage />
    </v-main>
  </v-app>
</template>

<script>

import Header from './components/PageHeader.vue'
import FooterPage from './components/PageFooter.vue'
import Loader from './components/PageLoader.vue'

export default {
  name: 'App',
  components: {
    Header,
    FooterPage,
    Loader
  },
  data: () => ({
  }),
  methods: {
  },
  created() {
  },
}
</script>
<style>
@import "@/assets/styles/bootstrap-4.1.2/bootstrap.min.css";
@import "@/assets/plugins/font-awesome-4.7.0/css/font-awesome.min.css";
@import "@/assets/styles/blog.css";
@import "@/assets/styles/blog_responsive.css";
@import "@/assets/styles/contact.css";
@import "@/assets/styles/contact_responsive.css";
@import "@/assets/styles/main_styles.css";
@import "@/assets/styles/responsive.css";


@media (max-width:767px) {
.hero-wrap.hero-wrap-2 {
    height: 100px;
}
.alturatopocel{
  padding-top: 120px;
}

.hero-wrap.hero-wrap-2 .slider-text {
    height: 110px;
}

.hero-wrap.hero-wrap-2 .slider-text .bread {
    font-weight: 900;
    color: #fff;
    font-size: 18px;
}
}


@media (max-width:767px) {
  .hidden-xs {
    display: none !important;
  }

  .p-5 {
    padding: 1rem !important;
}

.wrap-about {
    margin-bottom: 0px;
}
}

@media (min-width:768px) and (max-width:991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width:1200px) {
  .hidden-lg {
    display: none !important;
  }
  .alturatopocel{
  padding-top: 180px;
}
}

 /* WHATSAPP FIXO */
 .whatsapp-fixo {
      position: fixed;
      bottom: 10px;
      right: 5px;
      z-index: 999;
  }
</style>
