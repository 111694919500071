<template>
  <!-- Footer -->
	<footer class="footer">
		<div class="whatsapp-fixo" v-if="rodape!=''">
    			<a :href="rodape[0].itiCampoExtra1" target="_blank">
        		<img src="/images/atendimentowats.png"  alt="Fale Conosco pelo WhatsApp" />
    		</a>
  		</div>
		<div class="footer_content" v-if="rodape!= ''">
			<div class="container">
				<div class="row">
					
					<!-- Footer Column -->
					<div class="col-xl-3 col-lg-4 footer_col">
						<div class="footer_about">
							<div class="footer_logo" ><router-link to="/"><img src="/images/logoRodape.jpg" style="height: 60px; width: auto; border-radius: 5px;" alt=""></router-link></div>
							<div class="footer_text">
								<p v-html="rodape[0].itiResumo1">
								</p>
							</div>
							<div class="social">
								<ul>
									<ul class="d-flex flex-row align-items-center justify-content-start" v-if="redes!=''">
									<li v-if="redes[0].itiReferencia!=''"><a :href="redes[0].itiReferencia"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
								</ul>
								</ul>
							</div>
						</div>
					</div>

					<!-- Footer Column -->
					<div class="col-xl-4 col-lg-4 footer_col" >
						<div class="footer_column">
							<div class="footer_title">Contatos</div>
							<div class="footer_info">
								<ul>
									<!-- Phone -->
									<li class="d-flex flex-row align-items-center justify-content-start">
										<div><img src="/images/phone-call.svg" alt=""></div>
										<span><a :href="rodape[0].itiCampoExtra1" target="_blank" style="color: #6c7079;">{{rodape[0].itiNome}}</a></span> 
									</li>
									<!-- Address -->
									<li class="d-flex flex-row align-items-center justify-content-start">
										<div><img src="/images/phone-call.svg" alt=""></div>
										<span><a :href="rodape[0].itiCampoExtra1" target="_blank" style="color: #6c7079;">{{rodape[0].itiReferencia}}</a></span>
									</li>
									<!-- Email -->
									<li class="d-flex flex-row align-items-center justify-content-start">
										<div><img src="/images/envelope.svg" alt=""></div>
										<span>{{rodape[0].itiCampoExtra2}}</span>
									</li>
								</ul>
							</div>
							<div class="footer_links usefull_links">
								<div class="footer_title"></div>
							</div>
						</div>
					</div>

					<!-- Footer Column -->
					<div class="col-xl-2 col-lg-2 footer_col">
						<div class="footer_links">
							<div class="footer_title">Links Úteis</div>
							<ul>
								<li><router-link to="/">Home</router-link></li>
								<li><router-link to="/sobre">Sobre</router-link></li>
								<li><router-link to="/condominios">Nossos Condomînios</router-link></li>
								<li><router-link to="/contato">Contato</router-link></li>
							</ul>
						</div>
					</div>

					<!-- Footer Column -->
					<div class="col-xl-3 col-lg-3 footer_col">
						<div class="footer_title">Agende sua Reserva</div>
							<div class="footer_submit"><a :href="rodape[0].itiCampoExtra4" target="_blank">{{rodape[0].itiCampoExtra3}}</a></div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer_bar">
			<div class="container">
				<div class="row">
					<div class="col">
						<div class="footer_bar_content d-flex flex-md-row flex-column align-items-md-center align-items-start justify-content-start" style="justify-content: center !important;">
							<div class="copyright order-md-1 order-2">
								<a href="https://avancedigital.com.br/" target="_blank"><img src="/images/avanceBranco.png" alt=""></a>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import axios from 'axios';
export default {
  name: 'PageFooter',
  components: {

  },
  data: () => ({
    dados: [],
    rodape:[],
    whatsapp:[],
    redes:[],
  }),
  methods: {
    formattedDate(value) {
      return this.$moment(value).format('DD/MM/YYYY')
    },
    formataLink(value){
      return value.replaceAll('/','').replaceAll(' ','-').replaceAll('.','');
    }
  },
  created() {
    axios.get('/Process/InsInstitucional/institucional/10/rodape')
            .then(response => {
                this.rodape = response.data;
                console.log('this.rodape')
                console.log(this.rodape)
            })
            .catch(error => {
                console.error(error);
            });

    

       /* axios.get('/Process/InsInstitucional/institucional/38/whatsappflutuante')
            .then(response => {
                this.whatsapp = response.data;
            })
            .catch(error => {
                console.error(error);
            });*/

    
   

      axios.get('/Process/InsInstitucional/institucional/20/redessociais')
      .then(response => {
          this.redes = response.data
      })
      .catch(error => {
          console.error(error);
      });
    
  },
}
</script>

<style scoped>.menuTamanho {
  font-size: 20px;
  text-align: center;
}</style>
