<template>
  <div>
    <!-- Home -->

    <div class="home alturatopocel" v-if="condominiotopo!=''">
      <div class="parallax_background parallax-window" data-parallax="scroll"  data-speed="0.8" 
      :style="{backgroundImage: `url('${$imgURL + condominiotopo[0].insInstitucionalImagens[0].itgUrlImagem}')`,backgroundRepeat:'no-repeat'}"></div>
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="text-center">
                <div class="home_title" style="color: #fff; font-size: 30px; font-weight: 500;">{{condominiotopo[0].itiNome}}</div>
              </div>
            </div>
          </div>
        </div>
    </div>
  
    	<!-- Featured -->

	<div v-if="condominios!=''">
		<div class="container">
			<div class="row featured_row">
				
				<!-- Featured Item -->
				<div class="col-lg-4"  v-for="condominio in condominios" :key="condominio.itiId">
					<div class="listing">
						<div class="listing_image">
							<div class="listing_image_container">
								<img :src="$imgURL + condominio.insInstitucionalImagens[0].itgUrlImagem" alt="" style="height: 200px; width: 350px;">
							</div>
							<div class="tags d-flex flex-row align-items-start justify-content-start flex-wrap">
								<div class="tag tag_house"></div>
								<div class="tag tag_sale" v-if="contato!=''"><a :href="contato[0].itiCampoExtra5" target="_blank">Reserve</a></div>
							</div>
							<div class="tag_price listing_price">{{condominio.itiNome}}</div>
						</div>
						<div class="listing_content">
							<div class="prop_location listing_location d-flex flex-row align-items-start justify-content-start">
								<img src="/images/icon_1.png" alt="">
								<router-link :to="'/condominiosDetalhe/' + condominio.itiId + '/' + formataLink(condominios[0].itiNome)">Veja a localização e mais informações sobre o Condomínio. </router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>



  </div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useHead } from '@vueuse/head';
import CallAction from '@/components/PageCallAction.vue'
import axios from 'axios';

export default {
  name: 'CondominiosView',
  components: {
    Swiper,
    SwiperSlide,
    CallAction
  },
  data: () => ({
    condominiotopo:[],
    condominios:[],
    contato:[],
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Condominios - Viannas Participações',
        meta: [
          { name: "description", content: "" },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    formataLink(value){
      return value.replaceAll('/','').replaceAll(' ','-').replaceAll('.','');
    },
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
    
  },
  async created() {
    var _idioma = await this.ValidaIdioma();
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/29/condominiotopo')
      .then(response => {
          this.condominiotopo = response.data
          console.log('condominiotopo')
          console.log(this.condominiotopo)
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/30/condominios')
      .then(response => {
          this.condominios = response.data.filter(x=> x.itiVisivel).sort((a, b) => {
                    const valorA = parseInt(a.itiOrdem) || 0;
                    const valorB = parseInt(b.itiOrdem) || 0;
                    return valorA - valorB;
                });
                console.log('condominios')
                console.log(this.condominios)
                this.condominios.forEach(condominio =>{
                  condominio.insInstitucionalImagens.sort((a, b) => {
                    const valorA = parseInt(a.itgOrdem) || 0;
                    const valorB = parseInt(b.itgOrdem) || 0;
                    return valorA - valorB;
                });
                })
               
      })
      .catch(error => {
          console.error(error);
      });
      axios.get('/Process/InsInstitucional/institucional/16/contato')
      .then(response => {
          this.contato = response.data
          console.log('contato')
          console.log(this.contato)
      })
      .catch(error => {
          console.error(error);
      });
  },
}
</script>

<style scoped>

table tr td{
  border: 1px solid #cccccc;
  height: 40px;
  padding: 5px;
  font-size: 13px;
}

thead tr th {
  border: 1px solid #cccccc;
  background-color: #f7f7f7;
  font-weight: 500;
  padding: 5px;
}

@media (max-width:767px) {

  table tr td{
  border: 1px solid #cccccc;
  height: 83px !important;
  padding: 5px;
  font-size: 13px;
}
}

</style>
