<template>
  <div>
    <!-- Home -->

     <div class="home alturatopocel" v-if="sobretopo!=''">
      <div class="parallax_background parallax-window" data-parallax="scroll"  data-speed="0.8" 
      :style="{backgroundImage: `url('${$imgURL + sobretopo[0].insInstitucionalImagens[0].itgUrlImagem}')`, backgroundRepeat:'no-repeat'}"></div>
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="text-center">
                <div class="home_title" style="color: #fff; font-size: 30px; font-weight: 500;">{{sobretopo[0].itiNome}}</div>
              </div>
            </div>
          </div>
        </div>
    </div>

	<div class="intro" v-if="sobre!= ''" style="padding-bottom: 40px;">
		<div class="container">
			<div class="row row-eq-height">
				<div class="col-lg-6" >
					<div class="intro_content">
						<div class="section_title_container">
							<div class="section_subtitle">{{sobre[0].itiNome}}</div>
							<div class="section_title"><h1>{{sobre[0].itiReferencia}}</h1></div>
						</div>
						<div class="intro_text">
							<p style="text-align: justify;" v-html="sobre[0].itiResumo1">
							</p>
						</div>
					</div>
				</div>

				<!-- Intro Image -->
				<div class="col-lg-6 intro_col" v-if="sobre!= ''">
					<div class="intro_image">
						<div class="background_image" :style="{ backgroundImage: 'url(' + $imgURL + sobre[0].insInstitucionalImagens[0].itgUrlImagem + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }"></div>
						<img :src="$imgURL + sobre[0].insInstitucionalImagens[0].itgUrlImagem">>
					</div>
				</div>
			</div>
		</div>
	</div>
  </div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import axios from 'axios';
import { useHead } from '@vueuse/head';
import Depoimentos from '@/components/PageDepoimentos.vue'
import CallAction from '@/components/PageCallAction.vue'

export default {
  name: 'SobreView',
  components: {
    Swiper,
    SwiperSlide,
    Depoimentos,
    CallAction
  },
  data: () => ({
    sobretopo:[],
    sobre:[],
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Sobre - Vianna Participações',
        meta: [
          { name: "description", content: "" },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
    formattedDate(value) {
      return this.$moment(value).format('DD/MM/YYYY')
    },
    formataLink(value){
      return value.replaceAll('/','').replaceAll(' ','-').replaceAll('.','');
    },
    EnviaEmail(){
      if (this.Nome == '') {
        this.$mensagemAviso("Nome Obrigatório.")
      }
      else if (this.Email == '') {
        this.$mensagemAviso("E-mail Obrigatório.")
      }
      else {
        let _dadosEmail = {
          nomeDe: this.Nome,
          nomePara: "Advance Elevadores",
          assunto: 'Contato do Site Sobre - ' + this.Nome,
          destinatario: this.sobreequipe[0].itiReferencia,
          emailResposta: this.Email,
          textoEmail: "Nome: " + this.Nome + "<br>" + "E-mail: " + this.Email + "<br>Mensagem: " + this.Mensagem,
          nomeAnexo: '',
          anexo: '',
          tipoAnexo: ''
        }
      }
    },
  },
  async created() {
    var _idioma = await this.ValidaIdioma();

    this.carregaMetas();

    await axios.get('/Process/InsInstitucional/institucional/11/sobretopo')
      .then(response => {
          this.sobretopo = response.data
          console.log('sobretopo')
          console.log(this.sobretopo)
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/12/sobre')
      .then(response => {
          this.sobre = response.data
          console.log('sobre')
          console.log(this.sobre)
      })
      .catch(error => {
          console.error(error);
      });

  },
}
</script>
<style>
</style>
